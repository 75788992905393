<template>
	<div>
		<q-tabs
			inline-label
			active-color="primary"
			indicator-color="primary"
		>
			<q-route-tab
				v-for="tab in tabs_data"
				:key="tab.path"
				:name="tab.name"
				:label="$q.screen.gt.xs ? tab.name : tab.short_name"
				:to="tab.path"
			/>
		</q-tabs>
		<router-view></router-view>
	</div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
	name: "Dashboard",
	meta: {
		title: 'Alert Cascade - Dashboard'
	},
	data() {
		return {
			tabs_data: [],
			active_tab_name: '',
		}
	},
	methods: {
		...mapActions(["logout", "set_active_item"]),
		createTabData(){
			let permission = this.$store.getters.logged_user.customer_permission
			let root_cg_permissions =
				this.$store.getters.current_CG.root_customer_group_permissions
			let im_only_permission =
				root_cg_permissions.includes("Show Incident Manager dashboard only")
			let im_full_permission =
				root_cg_permissions.includes("Show Alert Cascade + Incident Manager dashboard");
			if (im_only_permission && ['ADMIN', 'OPERATOR'].includes(permission)){
				this.tabs_data.push({
					'path':'incident-manager',
					'name': 'Dashboard Incident Manager',
					'short_name': 'Incident Manager'
				})
				if ('Dashboard Incident Manager' != this.$route.name) {
					this.$router.push({name: 'Dashboard Incident Manager'})
				}
				return
			}
			if (['ADMIN', 'OPERATOR', 'SENDER'].includes(permission)){
				this.tabs_data.push({
					'path':'messages',
					'name': 'Dashboard Messages',
					'short_name': 'Messages'
				})
			}
			if (['ADMIN', 'OPERATOR'].includes(permission) && im_full_permission){
				this.tabs_data.unshift({
					'path':'incident-manager',
					'name': 'Dashboard Incident Manager',
					'short_name': 'Incident Manager'
				})
			}

			this.tabs_data.push({
				'path': 'profile',
				'name': 'Dashboard Profile',
				'short_name': 'Profile'
			})
			this.active_tab_name = this.tabs_data[0].name
			if (this.active_tab_name != this.$route.name) {
				this.$router.push({name: this.active_tab_name})
			}
		}
	},
	watch:{
		$route (to, from){
			if (to.name == 'Dashboard'){
				this.$router.push({name: this.active_tab_name})
			}
		}
	},
	created() {
		this.createTabData()
		this.set_active_item(1)
	},
}
</script>

<style>

.dashboard-shortcuts {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	list-style: none;
	flex: 0 0 33.333333%;
}

.dashboard-shortcut {
	width: 33%;
	float: left;
	text-align: center;
	text-decoration: none;
}

.dashboard-shortcut-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #192320;
	height: 84px;
	width: 84px;
	margin-left: auto;
	margin-right: auto;
	color: #ffffff;
	border-radius: 100px;
	cursor: pointer;
	margin-bottom: 10px;
}
</style>
